import React from "react";
import "./main.css";
import HalloweenBeams from "./BackGroundBeam";
import Navbar from "../navBar/navBar";
import { Carrousel } from "./cards";

function HalloweenMainPage() {
  return (
    <div className="bg-gradient-to-b from-black via-purple-900 to-orange-900 min-w-full min-h-screen flex justify-center items-center relative overflow-hidden">
      <HalloweenBeams />
      <Navbar />
      <div className="relative z-10 p-6 max-w-screen-lg mx-auto text-white">
        <Carrousel />
      </div>
      <div className="absolute inset-0 z-0 bg-gradient-radial from-transparent to-[#00000066]"></div>
    </div>
  );
}

export default HalloweenMainPage;
