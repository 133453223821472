import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

function ClockTimer(props) {
  const [timeRemaining, setTimeRemaining] = useState(props.initialTime || 600);

  useEffect(() => {
    let timer;
    if (!props.shouldStop && timeRemaining > 0) {
      timer = setTimeout(() => {
        setTimeRemaining(timeRemaining - 1);
        props.onTimeChange(timeRemaining - 1);
      }, 1000);
    } else {
      clearTimeout(timer);
      if (props.onTimeEnd) props.onTimeEnd();
    }
    return () => clearTimeout(timer); // Cleanup on unmount
  }, [timeRemaining, props.shouldStop]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  return (
    <motion.div
      initial={{ opacity: 0.0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        delay: 0.3,
        duration: 0.8,
        ease: "easeInOut",
      }}
      className="fixed top-14 right-4 bg-black dark:bg-white text-white dark:text-black p-2 rounded-lg shadow-lg"
    >
      <div className="text-lg font-mono">
        {formatTime(timeRemaining)}
      </div>
    </motion.div>
  );
}

export default ClockTimer;
