import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { GET_UNLOCKS, UNLOCK_ACHIEVEMENTS } from '../../../enpoints';
import getArchi from '../../../achiv';
import './unlockables.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { getCookieValue } from '../../../authSlide';

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#616161',
  color: '#FFFFFF',
  padding: '20px',
}));

const Unlockables = ({ number_rows }) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage] = useState(number_rows);
  const [order, setOrder] = useState('asc');
  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    const getUnlocks = async () => {
      const token = getCookieValue('auth_token');
      const formData = { token: token };
      try {
        const response = await axios.get(GET_UNLOCKS);
        const responseUnlock = await axios.post(UNLOCK_ACHIEVEMENTS, formData);
        const achievements = responseUnlock.data.length > 1 ? responseUnlock.data : response.data;
        sortData(achievements, order);
        setData(achievements);
      } catch (error) {
        console.error('Error fetching unlocks:', error);
      }
    };

    getUnlocks();
  }, [order]);

  const sortData = (unsortedData, order = 'asc') => {
    const dataEntries = Object.values(unsortedData).flat();

    const sorted = dataEntries.sort((a, b) => {
      if (a.unlocked === b.unlocked) return 0;
      return order === 'asc' ? a.unlocked - b.unlocked : b.unlocked - a.unlocked;
    });

    setSortedData(sorted);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRequestSort = () => {
    const isAsc = order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
  };

  return (
    <div className="w-full max-w-4xl mx-auto rounded-xl p-4 md:p-8 overflow-auto">
      <div className="flex justify-center space-x-4 mb-10">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 0}
          className="relative inline-flex h-12 overflow-hidden rounded-lg p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 dark:focus:ring-offset-slate-800 transition-all duration-300 hover:bg-slate-950 dark:hover:bg-slate-100"
        >
          <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
          <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-lg bg-slate-100 dark:bg-slate-950 px-6 py-2 text-sm font-medium text-black dark:text-white backdrop-blur-3xl transition-all duration-300 hover:bg-slate-950 hover:text-white dark:hover:bg-slate-100 dark:hover:text-black">
            Anterior &larr;
          </span>
        </button>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage >= Math.ceil(sortedData.length / rowsPerPage) - 1}
          className="relative inline-flex h-12 overflow-hidden rounded-lg p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 dark:focus:ring-offset-slate-800 transition-all duration-300 hover:bg-slate-950 dark:hover:bg-slate-100"
        >
          <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
          <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-lg bg-slate-100 dark:bg-slate-950 px-6 py-2 text-sm font-medium text-black dark:text-white backdrop-blur-3xl transition-all duration-300 hover:bg-slate-950 hover:text-white dark:hover:bg-slate-100 dark:hover:text-black">
            Siguiente &rarr;
          </span>
        </button>
        <button
          onClick={handleRequestSort}
          className="relative inline-flex h-12 overflow-hidden rounded-lg p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 dark:focus:ring-offset-slate-800 transition-all duration-300 hover:bg-slate-950 dark:hover:bg-slate-100"
        >
          <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
          <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-lg bg-slate-100 dark:bg-slate-950 px-6 py-2 text-sm font-medium text-black dark:text-white backdrop-blur-3xl transition-all duration-300 hover:bg-slate-950 hover:text-white dark:hover:bg-slate-100 dark:hover:text-black">
            {order === 'asc' ? 'Descendente ⬇️' : 'Ascendente ⬆️'}
          </span>
        </button>
      </div>
      <div className="overflow-x-auto">
        <TableContainer component={Paper} className="bg-white dark:bg-black rounded-xl shadow-md">
          <Table>
            <TableHead>
              <TableRow>
                <CustomTableCell align="center">Completado</CustomTableCell>
                <CustomTableCell align="left">Imagen</CustomTableCell>
                <CustomTableCell align="left">Nombre</CustomTableCell>
                <CustomTableCell align="left">Descripción</CustomTableCell>
                <CustomTableCell align="left">Rango</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData
                .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                .map((achievement, index) => (
                  <TableRow key={index}>
                    <CustomTableCell align="center">
                      {achievement.unlocked ? (
                        <label className="btn-lock">
                          <svg width="36" height="40" viewBox="0 0 36 40">
                            <path className="lockb" d="M27 27C27 34.1797 21.1797 40 14 40C6.8203 40 1 34.1797 1 27C1 19.8203 6.8203 14 14 14C21.1797 14 27 19.8203 27 27ZM15.6298 26.5191C16.4544 25.9845 17 25.056 17 24C17 22.3431 15.6569 21 14 21C12.3431 21 11 22.3431 11 24C11 25.056 11.5456 25.9845 12.3702 26.5191L11 32H17L15.6298 26.5191Z"></path>
                            <path className="lock" d="M6 21V10C6 5.58172 9.58172 2 14 2V2C18.4183 2 22 5.58172 22 10V21"></path>
                          </svg>
                        </label>
                      ) : (
                        <label className="btn-unlock">
                          <svg width="36" height="40" viewBox="0 0 36 40">
                            <path className="lockb" d="M27 27C27 34.1797 21.1797 40 14 40C6.8203 40 1 34.1797 1 27C1 19.8203 6.8203 14 14 14C21.1797 14 27 19.8203 27 27ZM15.6298 26.5191C16.4544 25.9845 17 25.056 17 24C17 22.3431 15.6569 21 14 21C12.3431 21 11 22.3431 11 24C11 25.056 11.5456 25.9845 12.3702 26.5191L11 32H17L15.6298 26.5191Z"></path>
                            <path className="lock" d="M6 21V10C6 5.58172 9.58172 2 14 2V2C18.4183 2 22 5.58172 22 10V21"></path>
                          </svg>
                        </label>
                      )}
                    </CustomTableCell>
                    <CustomTableCell>
                      <div
                        style={{ backgroundImage: `url(${getArchi(achievement.image)})` }}
                        className="profile_container w-16 h-16 bg-cover bg-center rounded-full"
                      ></div>
                    </CustomTableCell>
                    <CustomTableCell align="left">{achievement.name}</CustomTableCell>
                    <CustomTableCell align="left">{achievement.description}</CustomTableCell>
                    <CustomTableCell align="left">{achievement.rank}</CustomTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Unlockables;
