import React from "react";
import AuroraBackground from "../describe/aurora";
import { motion } from "framer-motion";
import Navbar from "../navBar/navBar";
import { CarrouselPayments } from "../mainPage/cards";
import { CheckoutForm, Return } from "./payment";

export const ReturnCheckout = () => (

    <AuroraBackground>
        <motion.div
            initial={{ opacity: 0.0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
                delay: 0.3,
                duration: 0.8,
                ease: "easeInOut",
            }}
            className="relative flex flex-col md:flex-row gap-8 items-center justify-center px-6 py-12"
        >
            <Navbar />
            <Return></Return>
        </motion.div>
    </AuroraBackground>


);

export default ReturnCheckout;

