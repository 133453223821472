import React, { useState } from 'react';
import { ToastContainer } from "react-toastify";
import Label from "../logIn/label";
import Input from "../logIn/input";
import { cn } from "../../utils/cn";
import { getCookieValue } from '../../authSlide';

const CreateGameModal = ({ handleCreateGame, handleCopyToClipboard }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState({
        token: getCookieValue("auth_token"),
        number_questions: 10,
        category: 'random'
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleCreateGame(formData);
        setIsOpen(false); // Cerrar modal después de crear la partida
    };

    return (
        <>
            <button
                className="relative inline-flex h-12 w-full max-w-xs overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 dark:focus:ring-offset-slate-800 transition-all duration-300 hover:bg-slate-950 dark:hover:bg-slate-100 mb-4"
                onClick={() => setIsOpen(true)}
            >
                <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-100 dark:bg-slate-950 px-6 py-2 text-sm font-medium text-black dark:text-white backdrop-blur-3xl transition-all duration-300 hover:bg-slate-950 hover:text-white dark:hover:bg-slate-100 dark:hover:text-black">
                    Crear Partida
                </span>
            </button>

            {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="relative w-full max-w-md mx-auto rounded-2xl p-4 md:p-8 shadow-input bg-white dark:bg-black neon-border dark:neon-border">
                        <h2 className="font-bold text-xl text-neutral-800 dark:text-neutral-200 mb-2 text-center">
                            Cree su propio thinkers
                        </h2>
                        <form className="my-4" onSubmit={handleSubmit}>
                            <div className="mb-2">
                                <Label htmlFor="number_questions">Número de Preguntas</Label>
                                <Input
                                    id="number_questions"
                                    name="number_questions"
                                    placeholder={formData.number_questions}
                                    type="text"
                                    value={formData.number_questions}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="category">Categoría</label>
                                <select
                                    id="category"
                                    name="category"
                                    className={cn(
                                        `flex h-10 w-full border-none bg-gray-50 dark:bg-zinc-800 text-black dark:text-white shadow-input rounded-md px-3 py-2 text-sm file:border-0 file:bg-transparent 
                            file:text-sm file:font-medium placeholder:text-neutral-400 dark:placeholder-text-neutral-600 
                            focus-visible:outline-none focus-visible:ring-[2px] focus-visible:ring-neutral-400 dark:focus-visible:ring-neutral-600
                            disabled:cursor-not-allowed disabled:opacity-50
                            dark:shadow-[0px_0px_1px_1px_var(--neutral-700)]
                            group-hover/input:shadow-none transition duration-400
                            `
                                    )}
                                    value={formData.category}
                                    onChange={handleInputChange}
                                >
                                    <option value="random">Aleatorio</option>
                                    <option value="history">Historia</option>
                                </select>
                            </div>

                            <div className="relative top-5 left-1/2 transform -translate-x-1/2 w-full max-w-[90%] flex justify-center space-x-4 mb-10">
                                <button
                                    className="relative inline-flex h-12 overflow-hidden rounded-lg p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 dark:focus:ring-offset-slate-800 transition-all duration-300 hover:bg-slate-950 dark:hover:bg-slate-100"
                                    type="submit"
                                >
                                    <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                                    <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-lg bg-slate-100 dark:bg-slate-950 px-6 py-2 text-sm font-medium text-black dark:text-white backdrop-blur-3xl transition-all duration-300 hover:bg-slate-950 hover:text-white dark:hover:bg-slate-100 dark:hover:text-black">
                                        Crear &rarr;
                                    </span>
                                </button>
                                <button
                                    className="relative inline-flex h-12 overflow-hidden rounded-lg p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 dark:focus:ring-offset-slate-800 transition-all duration-300 hover:bg-slate-950 dark:hover:bg-slate-100"
                                    onClick={() => setIsOpen(false)} // Cerrar el modal al hacer clic en cancelar
                                >
                                    <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                                    <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-lg bg-slate-100 dark:bg-slate-950 px-6 py-2 text-sm font-medium text-black dark:text-white backdrop-blur-3xl transition-all duration-300 hover:bg-slate-950 hover:text-white dark:hover:bg-slate-100 dark:hover:text-black">
                                        Cancelar
                                    </span>
                                </button>
                            </div>
                        </form>
                        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
                    </div>
                </div>
            )}

            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />

        </>
    );
};

export default CreateGameModal;
