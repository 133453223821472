import React, { useCallback, useState, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';  // Added axios import
import { getCookieValue } from "../../authSlide.js";  // Added getCookieValue import
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {
  Navigate
} from "react-router-dom";
import { GET_SESSION_STATUS, POST_CREATE_CHECKOUT, STRIPE_KEY } from "../../enpoints";
import AuroraBackground from "../describe/aurora.js";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test secret API key.
const stripePromise = loadStripe(STRIPE_KEY);  // Moved API key to env variable

export const CheckoutForm = () => {
  const [clientSecret, setClientSecret] = useState(null);

  const fetchClientSecret = useCallback(() => {
    const formData = {
      token: getCookieValue("auth_token"),
    };
    // Create a Checkout Session
    return axios
      .post(POST_CREATE_CHECKOUT, formData)
      .then((response) => {
        // Ensure the response contains the client secret
        if (response.data && response.data.clientSecret) {
          console.log("Session created, client secret:", response.data.clientSecret);
          setClientSecret(response.data.clientSecret);
        } else {
          throw new Error("Client secret not found in response");
        }
      })
      .catch((error) => {
        console.error("Error while making the request:", error);
        throw error;
      });
  }, []);

  useEffect(() => {
    fetchClientSecret();
  }, [fetchClientSecret]);

  if (!clientSecret) {
    return <div>Loading...</div>;  // Display a loading state until the clientSecret is fetched
  }

  return (
    <div id="checkout" className=" mt-20 max-h-[80%] overflow-auto">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={{ clientSecret }}  // Only set the clientSecret once
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
}


export const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
    console.log('Session ID:', sessionId); // Agregar esta línea
    axios
      .get(GET_SESSION_STATUS(sessionId))
      .then((data) => {
        setStatus(data.data.status);
        setCustomerEmail(data.customer_email);
      });
  }, []);

  if (status === 'open') {
    return (
      <Navigate to="/checkout" />
    )
  }

  if (status === 'complete') {
    return (
      <section id="success">
        <AuroraBackground>
          <motion.div
            initial={{ opacity: 0.0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              delay: 0.3,
              duration: 0.8,
              ease: "easeInOut",
            }}
            className="relative flex flex-col md:flex-row gap-8 items-center justify-center px-6 py-12 max-w-7xl mx-auto"
          >
            <div className="text-4xl font-bold text-center text-black dark:text-white">
              ¡Muchas gracias por su compra! En breve recibirá un correo con los detalles de su compra, y se activará su producto
            </div>
            <Link to="/" className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
              Volver al inicio
            </Link>
          </motion.div>
        </AuroraBackground>
      </section>
    )
  }

  return null;
}
