// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5vmin;
    max-width: 50vmin;
    width: 59%;
    min-width: 20%;
    margin: auto;
    border-radius: 5px; /* Bordes redondeados */
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.1); 
    background-color: rgba(240, 240, 240, 0.5);
  }
  
  .input {
    width: 93%;
    padding: 2vmin;
    margin-bottom: 1vmin;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .button {
    width: 101.5%;
    padding: 2vmin;
    border-radius: 5px;
    border: none;
    background-color: #28a745;
    color: #fff;
    cursor: pointer;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/signUp/signUp.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;IACd,iBAAiB;IACjB,UAAU;IACV,cAAc;IACd,YAAY;IACZ,kBAAkB,EAAE,uBAAuB;IAC3C,0CAA0C;IAC1C,0CAA0C;EAC5C;;EAEA;IACE,UAAU;IACV,cAAc;IACd,oBAAoB;IACpB,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,cAAc;IACd,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,WAAW;IACX,eAAe;EACjB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    padding: 5vmin;\n    max-width: 50vmin;\n    width: 59%;\n    min-width: 20%;\n    margin: auto;\n    border-radius: 5px; /* Bordes redondeados */\n    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.1); \n    background-color: rgba(240, 240, 240, 0.5);\n  }\n  \n  .input {\n    width: 93%;\n    padding: 2vmin;\n    margin-bottom: 1vmin;\n    border-radius: 5px;\n    border: 1px solid #ddd;\n  }\n  \n  .button {\n    width: 101.5%;\n    padding: 2vmin;\n    border-radius: 5px;\n    border: none;\n    background-color: #28a745;\n    color: #fff;\n    cursor: pointer;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
