import React from "react";
import "./main.css";
import BackgroundBeams from "./BackGroundBeam";
import Navbar from "../navBar/navBar";
import { Carrousel } from "./cards";

function MainPage() {
  return (
    <div className="back2">
      <BackgroundBeams></BackgroundBeams>
      <Navbar></Navbar>
      <div className="page">
        <div className="p-6">
          <Carrousel />
        </div>
        {/*<FootPage />*/}
      </div>
    </div>
  );
}

export default MainPage;
