import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 p-6 md:p-12">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold mb-6 text-blue-600 dark:text-blue-400">Política de Privacidad</h1>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2 text-blue-600 dark:text-blue-400">Responsable del Tratamiento</h2>
          <p>
            Nombre: <strong>Carlos Bilbao Lara</strong><br />
            Email: <strong>carlosbilbao2@gmail.com</strong>
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2 text-blue-600 dark:text-blue-400">Finalidad del Tratamiento</h2>
          <p>
            En esta página web, trato la información que me proporcionan los usuarios con el fin de gestionar sus consultas, facilitar el uso de los servicios ofrecidos, y enviar comunicaciones sobre actualizaciones o novedades del sitio. Los datos personales recabados serán únicamente los necesarios para las finalidades mencionadas.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2 text-blue-600 dark:text-blue-400">Legitimación</h2>
          <p>
            La base legal para el tratamiento de sus datos es el consentimiento que me ha proporcionado al aceptar esta política de privacidad y/o al proporcionarme sus datos personales.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2 text-blue-600 dark:text-blue-400">Destinatarios</h2>
          <p>
            Los datos personales recogidos no serán cedidos a terceros, salvo obligación legal o en el caso de que sea necesario para la prestación de servicios a través de terceros proveedores, quienes estarán sujetos a las mismas obligaciones de confidencialidad y protección de datos.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2 text-blue-600 dark:text-blue-400">Derechos de los Usuarios</h2>
          <p>
            Los usuarios tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.
          </p>
          <p>
            En determinadas circunstancias, los usuarios podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaré para el ejercicio o la defensa de reclamaciones.
          </p>
          <p>
            En determinadas circunstancias y por motivos relacionados con su situación particular, los usuarios podrán oponerse al tratamiento de sus datos. En ese caso, dejaré de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.
          </p>
          <p>
            Los usuarios tienen derecho a la portabilidad de sus datos, es decir, a recibir los datos personales que me hayan proporcionado en un formato estructurado, de uso común y lectura mecánica, y a transmitirlos a otro responsable del tratamiento sin que lo impida el responsable al que se los hubieran facilitado.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2 text-blue-600 dark:text-blue-400">Periodo de Conservación de los Datos</h2>
          <p>
            Los datos personales proporcionados se conservarán mientras se mantenga la relación con la web o durante los años necesarios para cumplir con las obligaciones legales.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2 text-blue-600 dark:text-blue-400">Medidas de Seguridad</h2>
          <p>
            He adoptado las medidas técnicas y organizativas necesarias para garantizar la seguridad de los datos personales y evitar su alteración, pérdida, tratamiento o acceso no autorizado, habida cuenta del estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a los que están expuestos.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2 text-blue-600 dark:text-blue-400">Uso de Cookies</h2>
          <p>
            Este sitio web utiliza cookies para mejorar la experiencia del usuario. Al navegar por el sitio, aceptas el uso de cookies de acuerdo con mi política de cookies.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2 text-blue-600 dark:text-blue-400">Reclamaciones</h2>
          <p>
            Si consideras que se ha vulnerado tu derecho a la protección de datos personales, puedes presentar una reclamación ante la Agencia Española de Protección de Datos (www.aepd.es).
          </p>
        </section>

        <p className="text-sm text-gray-600 dark:text-gray-400">
          Fecha de última actualización: <em>11 de agosto de 2024</em>
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
