import React, { useState } from "react";
import "./signUp.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { SIGNUP_URL } from "../../enpoints";
import AuroraBackground from "../describe/aurora";
import { motion } from "framer-motion";
import TextGenerateEffect from "../describe/generateText";
import Label from "../logIn/label";
import Input from "../logIn/input";
import { cn } from "../../utils/cn";


function SignUp() {
    const [formData, setFormData] = useState({
        name: "",
        username: "",
        email: "",
        password: "",
        image_path: "",
        nivel: ""
    });
    let rank = "bronze";
    let gp = 0;
    const navigate = useNavigate()

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const words = "Registrese para acceder"

    const handleSubmit = (e) => {
        e.preventDefault();
        axios
            .post(SIGNUP_URL, formData)
            .then((response) => {
                const token = response.data.token;
                const avatar = response.data.image_path
                const username = response.data.username
                const ai_tokens = response.data.ai_tokens
                const isConfirmed = response.data.is_confirmed;
                rank = response.data.rank;
                gp = response.data.gp;
                
                document.cookie = "isAuthenticated=true; path=/; max-age=7200; samesite=Lax";
                document.cookie = `auth_token=${token}; path=/; max-age=7200; samesite=Lax`;
                document.cookie = "sound=true; path=/; max-age=7200; samesite=Lax";
                document.cookie = `avatar=${avatar}; path=/; max-age=7200; samesite=Lax`;
                document.cookie = `username=${username}; path=/; max-age=7200; samesite=Lax`;
                document.cookie = `ai_tokens=${ai_tokens}; path=/; max-age=7200; samesite=Lax`;
                document.cookie = `isConfirmed=${isConfirmed}; path=/; max-age=7200; samesite=Lax`; // New line to save the is_confirmed status
                document.cookie = `rank=${rank}; path=/; max-age=7200; samesite=Lax`;
                document.cookie = `gp=${gp}; path=/; max-age=7200; samesite=Lax`;   
                toast.success("Registro exitoso. Por favor, confirme su email para proceder.");
                navigate("/confirmEmail");
            })
            .catch((error) => {
                console.error("Error al realizar la solicitud:", error);
                if (error.response && error.response.status === 403) {
                    toast.error("Error al realizar la solicitud: " + "El email ya está registrado.");
                }
                if (error.response === undefined || error.response.data.error === undefined) {
                    toast.error("Error al realizar la solicitud:" + error.message);
                } else {
                    toast.error("Error al realizar la solicitud:" + error.response.data.error);
                }
            });
    };

    return (
        <AuroraBackground>
            <motion.div
                initial={{ opacity: 0.0, y: 40 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{
                    delay: 0.3,
                    duration: 0.8,
                    ease: "easeInOut",
                }}
                className="relative flex flex-col gap-4 items-center justify-center px-4"
            >
                <div className="max-w-md w-full mx-auto rounded-2xl p-4 md:p-8 shadow-input bg-white dark:bg-black">
                    <h2 className="font-bold text-xl text-neutral-800 dark:text-neutral-200 mb-2 text-center">
                        Welcome to The Quizz BDP
                    </h2>
                    <p className="text-neutral-600 text-sm max-w-sm dark:text-neutral-300 mb-4 text-center">
                        <TextGenerateEffect
                            words={words}
                            className="font-extralight text-base md:text-4xl dark:text-neutral-200 py-2"
                        />
                    </p>
                    <form className="my-4" onSubmit={handleSubmit}>
                        <div className="mb-2">
                            <Label htmlFor="name">Name</Label>
                            <Input
                                id="name"
                                name="name"
                                placeholder="Name"
                                type="text"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-2">
                            <Label htmlFor="username">Username</Label>
                            <Input
                                id="username"
                                name="username"
                                placeholder="Username"
                                type="text"
                                value={formData.username}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-2">
                            <Label htmlFor="email">Email</Label>
                            <Input
                                id="email"
                                name="email"
                                placeholder="email@email.com"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-2">
                            <label htmlFor="nivel" className="block text-sm font-medium text-gray-700">Nivel/Curso académico</label>
                            <select
                                id="nivel"
                                name="nivel"
                                className={cn(
                                    `flex h-10 w-full border-none bg-gray-50 dark:bg-zinc-800 text-black dark:text-white shadow-input rounded-md px-3 py-2 text-sm  file:border-0 file:bg-transparent 
                                      file:text-sm file:font-medium placeholder:text-neutral-400 dark:placeholder-text-neutral-600 
                                      focus-visible:outline-none focus-visible:ring-[2px]  focus-visible:ring-neutral-400 dark:focus-visible:ring-neutral-600
                                      disabled:cursor-not-allowed disabled:opacity-50
                                      dark:shadow-[0px_0px_1px_1px_var(--neutral-700)]
                                      group-hover/input:shadow-none transition duration-400
                                      `
                                  )}                                
                                value={formData.nivel}
                                onChange={handleChange}
                            >
                                <option value="">Selecciona un curso</option>
                                <option value="1º Primaria">1º Primaria</option>
                                <option value="2º Primaria">2º Primaria</option>
                                <option value="3º Primaria">3º Primaria</option>
                                <option value="4º Primaria">4º Primaria</option>
                                <option value="5º Primaria">5º Primaria</option>
                                <option value="6º Primaria">6º Primaria</option>
                                <option value="1º ESO">1º ESO</option>
                                <option value="2º ESO">2º ESO</option>
                                <option value="3º ESO">3º ESO</option>
                                <option value="4º ESO">4º ESO</option>
                                <option value="1º Bachillerato">1º Bachillerato</option>
                                <option value="2º Bachillerato">2º Bachillerato</option>
                                <option value="Ciclo Formativo de Grado Medio">Ciclo Formativo de Grado Medio</option>
                                <option value="Ciclo Formativo de Grado Superior">Ciclo Formativo de Grado Superior</option>
                                <option value="Grado Universitario">Grado Universitario</option>
                                <option value="Máster Universitario">Máster Universitario</option>
                                <option value="Doctorado">Doctorado</option>
                            </select>
                        </div>
                        <div className="mb-2">
                            <Label htmlFor="password">Password</Label>
                            <Input
                                id="password"
                                name="password"
                                placeholder="Password"
                                type="password"
                                value={formData.password}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="flex justify-center">
                            <button
                                className="relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 dark:focus:ring-offset-slate-800 transition-all duration-300 hover:bg-slate-950 dark:hover:bg-slate-100"
                                type="submit"
                            >
                                <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                                <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-100 dark:bg-slate-950 px-6 py-2 text-sm font-medium text-black dark:text-white backdrop-blur-3xl transition-all duration-300 hover:bg-slate-950 hover:text-white dark:hover:bg-slate-100 dark:hover:text-black">
                                    Iniciar Sesión &rarr;
                                </span>
                            </button>
                        </div>
                        <div className="bg-gradient-to-r from-transparent via-blue-300 dark:via-blue-700 to-transparent my-4 h-[1px] w-full" />
                        <div className="text-center">
                            <button
                                className="text-sm mt-2 underline text-blue-500 hover:text-blue-700 focus:outline-none"
                                onClick={() => navigate("/logIn")}
                            >
                                ¿Tiene una cuenta? Inicie sesión aquí!
                            </button>
                        </div>
                        <ToastContainer
                            position="top-right"
                            autoClose={3000}
                            hideProgressBar={false}
                        />
                    </form>
                </div>
            </motion.div>
        </AuroraBackground>
    );
}

export default SignUp;
