"use client";
import React from "react";
import { Carousel, Card, CardPayment } from "../../utils/carruselCards";
import Stats from "../stats/showStats";
import getArchi from "../../achiv";
import Image from "../newDescribe/image";
import Unlockables from "../profile/unlockables/unlockables";
import { FaCheckCircle, FaEuroSign, FaCoins, FaWindows } from 'react-icons/fa';


const OfertaTokens = () => {
  return (
    <div className="text-white text-sm ">
      <ul className="space-y-4 text-left">
        <li className="flex items-left">
          <FaCheckCircle className="text-green-400 mr-2" />
          <span>Compre tokens por valor de <strong>5€ o 10€</strong></span>
        </li>
        <li className="flex items-left">
          <FaCheckCircle className="text-green-400 mr-2" />
          <span><strong>5€ - 100 tokens</strong></span>
        </li>
        <li className="flex items-center">
          <FaCheckCircle className="text-green-400 mr-2" />
          <span><strong>10€ - 200 + 50 tokens</strong> extra</span>
        </li>
        <li className="flex items-center">
          <FaCheckCircle className="text-green-400 mr-2" />
          <span><strong>Con los tokens podrás crear exámenes mediante IA</strong></span>
        </li>
      </ul>
      <button
        className="relative inline-flex h-12 overflow-hidden mt-12 rounded-lg p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 dark:focus:ring-offset-slate-800 transition-all duration-300 hover:bg-slate-950 dark:hover:bg-slate-100"
        onClick={() => window.location.href = "https://buy.stripe.com/3cseX69Ra5Q82JyaEE"}
      >
        <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
        <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-lg bg-slate-100 dark:bg-slate-950 px-6 py-2 text-sm font-medium text-black dark:text-white backdrop-blur-3xl transition-all duration-300 hover:bg-slate-950 hover:text-white dark:hover:bg-slate-100 dark:hover:text-black">
          ¡Compre aquí! &rarr;
        </span>
      </button>
    </div>
  );
};

const OfertaSubscripcion = () => {
  return (
    <div className="text-white text-sm ">
      <ul className="space-y-4 text-left">
        <li className="flex items-left">
          <FaCheckCircle className="text-green-400 mr-2" />
          <span>5€ al mes y obten los siguientes beneficios mientras la subscripción esté activa</span>
        </li>
        <li className="flex items-left">
          <FaCheckCircle className="text-green-400 mr-2" />
          <span>Obtenga acceso a 100 Q4L AI cada mes (no acumulables)</span>
        </li>
        <li className="flex items-left">
          <FaCheckCircle className="text-green-400 mr-2" />
          <span>Obten un icono de perfil exclusivo durante</span>
        </li>
        <li className="flex items-center">
          <FaCheckCircle className="text-green-400 mr-2" />
          <span>Cree tantas partidas privadas como desee</span>
        </li>
        <li className="flex items-center">
          <FaCheckCircle className="text-green-400 mr-2" />
          <span>Cree tantas partidas privadas a partir de Q4L AI</span>
        </li>
        <li className="flex items-center">
          <FaCheckCircle className="text-green-400 mr-2" />
          <span>Suba sus propias preguntas (próximamente)</span>
        </li>
      </ul>
      <button
        className="relative inline-flex h-12 overflow-hidden mt-12 rounded-lg p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 dark:focus:ring-offset-slate-800 transition-all duration-300 hover:bg-slate-950 dark:hover:bg-slate-100"
        onClick={() => window.location.href = "https://buy.stripe.com/5kA5mwd3mfqIgAo4gh"}

      >
        <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
        <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-lg bg-slate-100 dark:bg-slate-950 px-6 py-2 text-sm font-medium text-black dark:text-white backdrop-blur-3xl transition-all duration-300 hover:bg-slate-950 hover:text-white dark:hover:bg-slate-100 dark:hover:text-black">
          ¡Compre aquí! &rarr;
        </span>
      </button>
    </div>
  );
};


export function Carrousel() {
  const cards = data.map((card, index) => (
    <Card key={card.src} card={card} index={index} />
  ));

  return (
    <div className="w-full h-full py-20">
      <h2 className="max-w-7xl pl-4 mx-auto text-xl md:text-5xl font-bold text-neutral-200 dark:text-neutral-200 font-sans">
        Welcome to the Quizz4Learn
      </h2>
      <Carousel items={cards} />
    </div>
  );
}

const DummyContent = () => {
  return (
    <>
      <div
        className="bg-[#F5F5F7] dark:bg-neutral-800 p-8 md:p-14 rounded-3xl mb-4"
      >
        <p className="text-neutral-600 dark:text-neutral-400 text-base md:text-2xl font-sans max-w-3xl mx-auto">
          <span className="font-bold text-neutral-700 dark:text-neutral-200">
            Notas del Parche 0.0.4
          </span>{" "}
          <p>- Corregido algunos errores en el servidor</p>
          <p>- Ahora llegará un email de confirmación de la compra</p>
          <p>- Ahora será necesario confirmar el email</p>
          <p>- Ahora para cambiar la contraseña será necesario hacerlo a través de un mail de confirmacion</p>
        </p>
      </div>
      <div
        className="bg-[#F5F5F7] dark:bg-neutral-800 p-8 md:p-14 rounded-3xl mb-4"
      >
        <p className="text-neutral-600 dark:text-neutral-400 text-base md:text-2xl font-sans max-w-3xl mx-auto">
          <span className="font-bold text-neutral-700 dark:text-neutral-200">
            Notas del Parche 0.0.3
          </span>{" "}
          <p>- Corregido algunos errores en la interfaz</p>
          <p>- Ahora se puede acceder a BDP AI!</p>
          <p>- Ahora se pueden comprar AI Tokens!</p>
          <p>- Ahora cuando te registrar llega un mail de conformacion</p>
        </p>
      </div>
      <div
        className="bg-[#F5F5F7] dark:bg-neutral-800 p-8 md:p-14 rounded-3xl mb-4"
      >
        <p className="text-neutral-600 dark:text-neutral-400 text-base md:text-2xl font-sans max-w-3xl mx-auto">
          <span className="font-bold text-neutral-700 dark:text-neutral-200">
            Notas del Parche 0.0.2
          </span>{" "}
          <p>- Corregido algunos errores en la interfaz para móviles</p>
          <p>- Añadidas nuevas funcionalidades</p>
          <p>- Ahora se pueden crear partidas privadas de thinkers</p>
          <p>- Se ha remodelado la pantalla inicial</p>
        </p>
      </div>
      <div
        className="bg-[#F5F5F7] dark:bg-neutral-800 p-8 md:p-14 rounded-3xl mb-4"
      >
        <p className="text-neutral-600 dark:text-neutral-400 text-base md:text-2xl font-sans max-w-3xl mx-auto">
          <span className="font-bold text-neutral-700 dark:text-neutral-200">
            Notas del Parche 0.0.1
          </span>{" "}
          <p>- Bienvenidos a Quizz4Learn!!</p>
          <p>- Compite aprende y diviertete</p>
        </p>
      </div>
    </>
  );
};

const CommingSoon = () => {
  return (
    <>
      <div
        className="bg-[#F5F5F7] dark:bg-neutral-800 p-8 md:p-14 rounded-3xl mb-4"
      >
        <p className="text-neutral-600 dark:text-neutral-400 text-base md:text-2xl font-sans max-w-3xl mx-auto">
          <span className="font-bold text-neutral-700 dark:text-neutral-200">
            Cambios en camino...
          </span>{" "}
          <p>- Sistema de rankeds en thinkers</p>
          <p>- MMR de los jugadores</p>
          <p>- Recompensas por clasificacion</p>
          <p>- Mejora en el sistema de trofeos</p>
          <p>- Nuevas preguntas!</p>
        </p>
      </div>
    </>
  );
};


const data = [
  {
    category: "Notas del parche",
    title: "PRE-ALPHA 0.0.4",
    src: getArchi("zeusgold"),
    content: <DummyContent />,
  },
  {
    category: "Comming Soon",
    title: "👀",
    src: getArchi("zeussilv"),
    content: <CommingSoon />,
  },
  {
    category: "Estadísticas",
    title: "AQUI!!!",
    src: getArchi("trophy"),
    content: <Stats></Stats>,
  },
  {
    category: "Desbloquea logros 🏆",
    title: "Y además gana recompensas!! (próximamente)",
    src: getArchi("stelar"),
    content: <Unlockables number_rows={10} />,
  },
];

export function CarrouselPayments() {
  const cards = data2.map((card, index) => (
    <CardPayment key={card.src} card={card} index={index} describe={card.describe} />
  ));

  return (
    <div className="w-full h-full py-20">
      <h2 className="max-w-7xl pl-4 mx-auto text-xl md:text-5xl font-bold text-neutral-800 dark:text-neutral-200 font-sans">
        Accede a los servicios pro de Quizz4Learn
      </h2>
      <Carousel items={cards} />
    </div>
  );
}


const data2 = [
  {
    category: "Obtén tus Tokens BDP AI!!!",
    describe: <OfertaTokens></OfertaTokens>,
  },
  {
    category: "Q4L Subscription",
    title: "¿Quieres prepararte mejor los exámenes?",
    describe: <OfertaSubscripcion></OfertaSubscripcion>,
  },
  {
    category: "Q4L Pro",
    title: "¿Eres profesor?",
    describe: "Accede a la versión PRO de Quizz4Learn",
    content: <Unlockables number_rows={10} />,
  },


];
