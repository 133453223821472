import React from 'react';
import { motion } from 'framer-motion';
import { FlipSentences } from '../mainPage/flipWords';


const LoadingSpinner = () => {
  const words = ["Nervioso por el exámen?", "Los he visto más rápidos", "Sigues esperando?", "Siempre es la c", "Sabía que no debía cambiar mi opción", "Repasamos el exámen?"];

  return (
    <motion.div
      initial={{ opacity: 1, scale: 1 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        delay: 0.0,
        duration: 10,
        ease: 'easeInOut',
        repeat: Infinity,
        repeatType: 'reverse'
      }}
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <div className="flex flex-col items-center gap-4">
        <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-white"></div>
        <FlipSentences words={words} />
      </div>
    </motion.div>
  );
};

export default LoadingSpinner;
