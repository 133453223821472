import React, { useState } from "react";
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AuroraBackground from "../describe/aurora";// Asegúrate de importar tu background si es necesario
import Label from "../logIn/label";
import Input from "../logIn/input";
import axios from "axios";
import { getCookieValue } from "../../authSlide";// Asegúrate de que esta función esté implementada
import { POST_ADD_EXPENSE } from "../../enpoints";
import { cn } from "../../utils/cn";
import Navbar from "../navBar/navBar";

const AddExpense = () => {
    const [formData, setFormData] = useState({
        token: getCookieValue("auth_token"),
        name: "Nomina",
        value: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios
          .post(POST_ADD_EXPENSE, formData)
          .then((response) => {
            console.log(response.data.data)
            toast.success("Datos guardados correctamente")
          })
          .catch((error) => {
            console.error("Error al realizar la solicitud:", error);
            if (error.response === undefined || error.response.data.error === undefined) {
              toast.error("Error al realizar la solicitud:" + error.message);
            } else {
              toast.error("Error al realizar la solicitud:" + error.response.data.error);
            }
          });
    };

    return (
        <AuroraBackground>
            <Navbar></Navbar>
            <motion.div
                initial={{ opacity: 0.0, y: 40 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{
                    delay: 0.3,
                    duration: 0.8,
                    ease: "easeInOut",
                }}
                className="relative flex flex-col gap-4 items-center justify-center px-4"
            >
                
                <div className="relative w-full max-w-md mx-auto rounded-2xl p-4 md:p-8 shadow-input bg-white dark:bg-black neon-border dark:neon-border">
                        <h2 className="font-bold text-xl text-neutral-800 dark:text-neutral-200 mb-2 text-center">
                            No añada nada, aun estoy trabajando en esto
                        </h2>
                        <form className="my-4" onSubmit={handleSubmit}>
                            <div className="mb-2">
                                <label htmlFor="name">Tipo</label>
                                <select
                                    id="name"
                                    name="name"
                                    className={cn(
                                        `flex h-10 w-full border-none bg-gray-50 dark:bg-zinc-800 text-black dark:text-white shadow-input rounded-md px-3 py-2 text-sm file:border-0 file:bg-transparent 
                            file:text-sm file:font-medium placeholder:text-neutral-400 dark:placeholder-text-neutral-600 
                            focus-visible:outline-none focus-visible:ring-[2px] focus-visible:ring-neutral-400 dark:focus-visible:ring-neutral-600
                            disabled:cursor-not-allowed disabled:opacity-50
                            dark:shadow-[0px_0px_1px_1px_var(--neutral-700)]
                            group-hover/input:shadow-none transition duration-400
                            `
                                    )}
                                    value={formData.value}
                                    onChange={handleInputChange}
                                >
                                    <option value="Nomina">Nomina</option>
                                    <option value="Transferencias positivas">Transferencias a favor</option>
                                    <option value="Envío de dinero">Envío de dinero</option>
                                    <option value="Comida"> Comida</option>
                                    <option value="Restaurantes"> Restaurantes</option>
                                    <option value="Ocio"> Ocio</option>
                                    <option value="Suscripciones"> Suscripciones</option>

                                </select>
                            </div>
                            <div className="mb-2">
                                <Label htmlFor="value">Cantidad</Label>
                                <Input
                                    id="value"
                                    name="value"
                                    placeholder={formData.value}
                                    type="text"
                                    value={formData.value}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="relative top-5 left-1/2 transform -translate-x-1/2 w-full max-w-[90%] flex justify-center space-x-4 mb-10">
                                <button
                                    className="relative inline-flex h-12 overflow-hidden rounded-lg p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 dark:focus:ring-offset-slate-800 transition-all duration-300 hover:bg-slate-950 dark:hover:bg-slate-100"
                                    type="submit"
                                >
                                    <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                                    <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-lg bg-slate-100 dark:bg-slate-950 px-6 py-2 text-sm font-medium text-black dark:text-white backdrop-blur-3xl transition-all duration-300 hover:bg-slate-950 hover:text-white dark:hover:bg-slate-100 dark:hover:text-black">
                                        Guardar &rarr;
                                    </span>
                                </button>
                            </div>
                        </form>
                        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
                    </div>
            </motion.div>
        </AuroraBackground>
    );
};

export default AddExpense;
