import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RESET_PASSWORD_URL } from '../../enpoints';
import AuroraBackground from '../describe/aurora';
import { motion } from 'framer-motion';
import Label from '../logIn/label';
import Input from '../logIn/input';

const PasswordReset = () => {
  const [formData, setFormData] = useState({
    token: '',
    new_password: '',
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(RESET_PASSWORD_URL, formData)
      .then((response) => {
        toast.success('Password reset successfully');
        navigate('/logIn');
      })
      .catch((error) => {
        console.error('Error while resetting password:', error);
        if (error.response === undefined || error.response.data.error === undefined) {
          toast.error('Error while resetting password: ' + error.message);
        } else {
          toast.error('Error while resetting password: ' + error.response.data.error);
        }
      });
  };

  return (
    <AuroraBackground>
      <motion.div
        initial={{ opacity: 0.0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{
          delay: 0.3,
          duration: 0.8,
          ease: 'easeInOut',
        }}
        className="relative flex flex-col gap-4 items-center justify-center px-4"
      >
        <div className="max-w-md w-full mx-auto rounded-2xl p-4 md:p-8 shadow-input bg-white dark:bg-black">
          <h2 className="font-bold text-xl text-neutral-800 dark:text-neutral-200 mb-2 text-center">
            Password Reset
          </h2>
          <form className="my-4" onSubmit={handleSubmit}>
            <div className="mb-2">
              <Label htmlFor="token">Token</Label>
              <Input
                id="token"
                name="token"
                placeholder="Enter your token"
                type="text"
                value={formData.token}
                onChange={handleChange}
              />
            </div>
            <div className="mb-2">
              <Label htmlFor="new_password">New Password</Label>
              <Input
                id="new_password"
                name="new_password"
                placeholder="Enter your new password"
                type="password"
                value={formData.new_password}
                onChange={handleChange}
              />
            </div>
            <div className="flex justify-center">
              <button
                className="relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 dark:focus:ring-offset-slate-800 transition-all duration-300 hover:bg-slate-950 dark:hover:bg-slate-100"
                type="submit"
              >
                <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-100 dark:bg-slate-950 px-6 py-2 text-sm font-medium text-black dark:text-white backdrop-blur-3xl transition-all duration-300 hover:bg-slate-950 hover:text-white dark:hover:bg-slate-100 dark:hover:text-black">
                  Reset Password &rarr;
                </span>
              </button>
            </div>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
          </form>
        </div>
      </motion.div>
    </AuroraBackground>
  );
};

export default PasswordReset;
