"use client";

import { motion } from "framer-motion";
import React from "react";
import AuroraBackground from "./aurora";
import TextGenerateEffect from "./generateText";
import { useNavigate } from "react-router-dom";

export function Describe() {
  const words = "Además, podrás subir tus apuntes u otros documentos y crear exámenes para preparte mejor";
  const words2 = "Crea una cuenta o inicia sesión para demostrar tu sabiduría y alcanzar la gloria."
  const navigate = useNavigate()

  return (
    <AuroraBackground>
      <motion.div
        initial={{ opacity: 0.0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{
          delay: 0.3,
          duration: 0.8,
          ease: "easeInOut",
        }}
        className="relative flex flex-col gap-4 items-center justify-center px-4"
      >
        <div className="flex items-center justify-center">
          <div className="text-3xl md:text-7xl font-bold dark:text-white text-center">
            Quizz4Learn
          </div>
          <div className="ml-2 text-xl md:text-2xl font-light dark:text-neutral-200">
            by <span className="font-bold">Carlosbil</span>
          </div>
        </div>
        <TextGenerateEffect words={words2} className="font-extralight text-base md:text-4xl dark:text-neutral-200 py-4" />
        <TextGenerateEffect words={words} className="font-extralight text-base md:text-4xl dark:text-neutral-200 py-4" />

        <button onClick={() => navigate("/logIn")} className="bg-black dark:bg-white rounded-full w-fit text-white dark:text-black px-4 py-2" aria-label="Inicia sesión aquí"  tabIndex="0">
          Inicia sesión aquí
        </button>
        <p className="font-bold dark:text-white text-center"> Esto es una versión pre-alpha, volved pronto para más novedades! </p>
        <p className="font-bold dark:text-white text-center"> Todos los datos podrían verse eliminados de cara a futuras versiones </p>
        <p className="font-bold dark:text-white text-center"> No introduzcan información personal debido a la versión tan temprana de la aplicación</p>

      </motion.div>
    </AuroraBackground>
  );
}

export default Describe;
