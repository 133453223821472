import React from "react";
import AuroraBackground from "../describe/aurora";
import { motion } from "framer-motion";
import Navbar from "../navBar/navBar";
import { CarrouselPayments } from "../mainPage/cards";
import { CheckoutForm } from "./payment";

export const Checkout = () => (

    <div className="">
        <Navbar />
        <CheckoutForm></CheckoutForm>
    </div>
);

export default Checkout;

