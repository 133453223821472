import React, { useEffect, useState } from "react";
import "./thinkers.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { POST_CREATE_THINKER, POST_JOIN_THINKER, TINKERS_URL } from "../../enpoints";
import ThinkersDisplayer from "./thinkersDisplayer";
import { getCookieValue } from "../../authSlide";
import { TextGenerateEffectWhite } from "../describe/generateText";
import Input from "../logIn/input";
import Navbar from "../navBar/navBar";
import { FaCopy } from "react-icons/fa";
import CreateGameModal from "./createGame";

export function HalloweenThinkers() {
  const [questions, setQuestions] = useState([]);
  const [start, setStart] = useState(false);
  const words2 = "1 minuto, 10 preguntas, ¿podrás acertar todas en el menor tiempo posible y ser el número 1?";
  const [id, setId] = useState(100000)
  const [thinkerId, setThinkerId] = useState(null);

  const [formData, setFormData] = useState({
    token: getCookieValue("auth_token"),
    id: "",
  });

  const handleCopyToClipboard = () => {
    if (thinkerId) {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(thinkerId)
          .then(() => {
            toast.success("ID copiado correctamente");
          })
          .catch(() => {
            toast.error("No se ha podido copiar el ID");
          });
      } else {
        // Fallback para dispositivos que no soportan navigator.clipboard
        const textArea = document.createElement("textarea");
        textArea.value = thinkerId;
        textArea.style.position = "fixed";  // Evita que el área de texto sea visible
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
          toast.success("ID copiado correctamente");
        } catch (err) {
          toast.error("No se ha podido copiar el ID");
        }
        document.body.removeChild(textArea);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCreateGame = (formdata) => {
    const jsonData = formdata
    axios
      .post(POST_CREATE_THINKER, jsonData)
      .then((response) => {
        setId(response.data.id)
        setThinkerId(response.data.id)
        toast.success("Partida privada creada correctamente")
      })
      .catch((error) => {
        console.error("Error al realizar la solicitud:", error);
        if (error.response === undefined || error.response.data.error === undefined) {
          toast.error("Error al realizar la solicitud:" + error.message);
        } else {
          toast.error("Error al realizar la solicitud:" + error.response.data.error);
        }
      });
  }

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent page reload on form submit
    axios
      .post(POST_JOIN_THINKER, formData)
      .then((response) => {
        setQuestions(response.data.questions);
        setStart(true)
        setId(response.data.id)
        setThinkerId(response.data.id)
      })
      .catch((error) => {
        console.error("Error al realizar la solicitud:", error);
        if (error.response === undefined || error.response.data.error === undefined) {
          toast.error("Error al realizar la solicitud:" + error.message);
        } else {
          toast.error("Error al realizar la solicitud:" + error.response.data.error);
        }
      });
  };

  const handleButtonClick = () => {
    setTimeout(() => {
      setStart(true);
    }, 1000); // Duración de la animación de deconstrucción
  };

  useEffect(() => {
    getQuestions();
  }, []);

  const getQuestions = () => {
    axios
      .get(TINKERS_URL)
      .then((response) => {
        setQuestions(response.data.questions);
      })
      .catch((error) => {
        console.error("Error al iniciar el juego", error);
        if (error.response === undefined || error.response.data.error === undefined) {
          toast.error("Error al iniciar el juego:" + error.message);
        } else {
          toast.error("Error al iniciar el juego:" + error.response.data.error);
        }
      });
  };

  return (
      <div className="bg-gradient-to-b from-black via-purple-900 to-orange-700 min-w-full min-h-screen flex justify-center items-center relative overflow-hidden">
        <Navbar />
        <b className="page">
          {!start && (
            <div>
              <div className="text-4xl md:text-7xl font-bold text-orange-500 text-center mb-4 tracking-wider drop-shadow-md animate-pulse">
                Spooky Thinkers
              </div>
              <TextGenerateEffectWhite
                words={words2}
                className="font-extralight text-lg md:text-4xl text-orange-300 py-4 text-center tracking-wide"
              />
              <div className="bg-gradient-to-r from-transparent via-purple-400 to-transparent dark:via-purple-700 my-4 h-[2px] w-full" />
    
              <div className="flex flex-col items-center mt-8">
                <div className="flex flex-row space-x-2">
                  <button
                    className="relative inline-flex h-12 w-full max-w-xs overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2 focus:ring-offset-black dark:focus:ring-offset-gray-800 transition-all duration-300 hover:bg-orange-600 dark:hover:bg-orange-300 mb-4"
                    onClick={handleButtonClick}
                  >
                    <span className="absolute inset-[-1000%] animate-[spin_3s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#FF7518_0%,#6344F5_50%,#FF7518_100%)]" />
                    <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-purple-900 dark:bg-purple-700 px-6 py-2 text-sm font-medium text-white dark:text-black backdrop-blur-3xl transition-all duration-300 hover:bg-orange-700 hover:text-black dark:hover:bg-orange-400 dark:hover:text-white">
                      Competitivo
                    </span>
                  </button>
                  <CreateGameModal handleCreateGame={handleCreateGame} handleCopyToClipboard={handleCopyToClipboard} />
                </div>
                {thinkerId && (
                  <div className="mt-4 flex items-center space-x-3">
                    <p className="text-lg font-medium text-orange-300 dark:text-orange-400">
                      Thinkers Privado: {thinkerId}
                    </p>
                    <button
                      onClick={handleCopyToClipboard}
                      className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-orange-500 dark:bg-purple-800 text-black dark:text-white hover:bg-orange-600 dark:hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-black dark:focus:ring-offset-gray-800 transition-all duration-300"
                    >
                      <FaCopy />
                    </button>
                  </div>
                )}
                <div className="bg-gradient-to-r from-transparent via-purple-400 to-transparent dark:via-purple-700 my-4 h-[2px] w-full" />
    
                <form className="w-full max-w-xs" onSubmit={handleSubmit}>
                  <Input
                    id="id"
                    name="id"
                    placeholder="ID"
                    type="text"
                    value={formData.id}
                    onChange={handleChange}
                    className="mb-4 text-orange-500 dark:text-orange-400"
                  />
                  <button
                    className="relative inline-flex h-12 w-full overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2 focus:ring-offset-black dark:focus:ring-offset-gray-800 transition-all duration-300 hover:bg-orange-600 dark:hover:bg-orange-300"
                    type="submit"
                  >
                    <span className="absolute inset-[-1000%] animate-[spin_3s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#FF7518_0%,#6344F5_50%,#FF7518_100%)]" />
                    <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-purple-900 dark:bg-purple-700 px-6 py-2 text-sm font-medium text-white dark:text-black backdrop-blur-3xl transition-all duration-300 hover:bg-orange-700 hover:text-black dark:hover:bg-orange-400 dark:hover:text-white">
                      Unirse a un Thinker &rarr;
                    </span>
                  </button>
                </form>
              </div>
            </div>
          )}
          {start && <ThinkersDisplayer questions_prop={questions} id_prop={id} />}
        </b>
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
      </div>
    );    
}

export default HalloweenThinkers;

