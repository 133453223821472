import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Image = ({ src, alt }) => (
  <LazyLoadImage
    alt={alt}
    src={src} // Carga la imagen
    effect="blur" // Aplica un efecto de desenfoque
    width="100%"
    height="auto"
    
  />
);

export default Image;
