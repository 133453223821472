import React from 'react';
import { jsPDF } from 'jspdf';

const JsonToPdf = ({ data }) => {

  const generatePDF = () => {
    const doc = new jsPDF();
    let yOffset = 10; // Initial offset from top
    const pageHeight = doc.internal.pageSize.height;
    const lineHeight = 10; // Height of each line
    const margin = 10; // Margin from edges
    const maxLineWidth = doc.internal.pageSize.width - margin * 2;

    data.forEach((item, index) => {
      const splitQuestion = doc.splitTextToSize(item.question, maxLineWidth);
      const splitAnswers = doc.splitTextToSize(item.answer, maxLineWidth);
      const questionHeight = splitQuestion.length * lineHeight;
      const requiredHeight = questionHeight + (item.options.length + 3) * lineHeight;

      if (yOffset + requiredHeight > pageHeight) {
        doc.addPage();
        yOffset = margin;
      }

      doc.text(`Pregunta ${index + 1}:`, margin, yOffset);
      yOffset += lineHeight;
      splitQuestion.forEach(line => {
        doc.text(line, margin, yOffset);
        yOffset += lineHeight;
      });

      //doc.text(`Dificultad: ${item.difficulty}`, margin, yOffset);
      //yOffset += lineHeight;
      doc.text(`Opciones:`, margin, yOffset);
      yOffset += lineHeight;
      item.options.forEach(option => {
        const splitOption = doc.splitTextToSize(option, maxLineWidth - 10);
        splitOption.forEach((line, lineIndex) => {
          if (lineIndex === 0) {
            doc.text(`- ${line}`, margin + 10, yOffset);
          } else {
            doc.text(line, margin + 20, yOffset);
          }
          yOffset += lineHeight;
        });
      });
      doc.text(`Respuesta:`, margin, yOffset);
      yOffset += lineHeight;
      splitAnswers.forEach(line => {
        doc.text(line, margin, yOffset);
        yOffset += lineHeight;
      });
      yOffset += lineHeight + 10; // Extra space between questions

      if (yOffset > pageHeight - 20) { // If space is running out, add new page
        doc.addPage();
        yOffset = margin;
      }
    });

    doc.save('document.pdf');
  };

  return (
    <button
      onClick={generatePDF}
      className="relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 dark:focus:ring-offset-slate-800 transition-all duration-300 hover:bg-slate-950 dark:hover:bg-slate-100"
    >
      <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
      <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-100 dark:bg-slate-950 px-6 py-2 text-sm font-medium text-black dark:text-white backdrop-blur-3xl transition-all duration-300 hover:bg-slate-950 hover:text-white dark:hover:bg-slate-100 dark:hover:text-black">
        Descargar PDF
      </span>
    </button>
  );
};

export default JsonToPdf;
