import { useState } from 'react';
import getArchi from '../../achiv';
import getAvatar from '../../avatars';
import { getCookieValue } from '../../authSlide';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const navigate = useNavigate()

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const toggleDropdownMenu = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <nav className="bg-white dark:bg-gray-800 shadow-md fixed w-full h-12 top-0 z-50">
            <div className="mx-auto flex justify-between items-center px-20">
                <div className="flex items-center space-x-4">
                    <a className="relative flex items-center space-x-4 w-60 left-0" href='/' aria-label="Inicio"  tabIndex="0">
                        <img src={getArchi("atgold")} alt="Logo" className="rounded h-12 w-12" />
                        <span className="text-xl font-bold text-black dark:text-white">Quizz4Learn</span>
                    </a>
                </div>
                <div>
                    <div className="hidden md:flex space-x-4">
                        <a href="/" className="text-black dark:text-white hover:underline" aria-label="Inicio"  tabIndex="0">Inicio</a>
                        <a href="/questions" className="text-black dark:text-white hover:underline" aria-label="Entrenamiento"  tabIndex="0">Entrenamiento</a>
                        <a href="/thinkers" className="text-black dark:text-white hover:underline" aria-label="Thinkers"  tabIndex="0">Thinkers</a>
                        <a href="/bdpAI" className="text-black dark:text-white hover:underline" aria-label="BDP AI"  tabIndex="0">BDP AI</a>
                        <a href="/offers" className="text-black dark:text-white hover:underline" aria-label="Tienda"  tabIndex="0">Tienda</a>
                        <a href="/aboutMe" className="text-black dark:text-white hover:underline" aria-label="About"  tabIndex="0">About</a>
                        <div className="relative">
                            <button onClick={toggleDropdownMenu} className="text-black dark:text-white hover:underline" aria-haspopup="true" aria-expanded={isDropdownOpen}  tabIndex="0">Más</button>
                            {isDropdownOpen && (
                                <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 shadow-lg rounded-md z-50">
                                    <a href="/privacy_politic" className="block px-4 py-2 text-black dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700" aria-label="Política de Privacidad"  tabIndex="0">Política de Privacidad</a>
                                    <a href="/contact" className="block px-4 py-2 text-black dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700" aria-label="Contacto"  tabIndex="0">Contacto</a>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex items-center space-x-4">
                    <button className="absolute top-3 right-3 md:hidden block text-black dark:text-white" onClick={toggleMobileMenu} aria-label="Abrir menú móvil"  tabIndex="0">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                        </svg>
                    </button>
                </div>

                <button onClick={() => navigate('/profile')} className="relative top-0 z-10 min-h-10 w-10 rounded-full shadow focus:outline-none focus:ring" aria-label="Perfil"  tabIndex="0">
                    <img className="h-full w-full object-cover" src={getAvatar(getCookieValue("avatar"))} alt="Your avatar" />
                </button>
            </div>

            {isMobileMenuOpen && (
                <div className="md:hidden rounded absolute right-0 z-20 w-48 bg-white dark:bg-gray-800 mt-2 flex flex-col space-y-2 px-4">
                    <a href="/" className="text-black dark:text-white hover:underline" aria-label="Inicio"  tabIndex="0">Inicio</a>
                    <a href="/questions" className="text-black dark:text-white hover:underline" aria-label="Entrenamiento"  tabIndex="0">Entrenamiento</a>
                    <a href="/thinkers" className="text-black dark:text-white hover:underline" aria-label="Thinkers"  tabIndex="0">Thinkers</a>
                    <a href="/bdpAI" className="text-black dark:text-white hover:underline" aria-label="BDP AI"  tabIndex="0">BDP AI</a>
                    <a href="/offers" className="text-black dark:text-white hover:underline" aria-label="Tienda"  tabIndex="0">Tienda</a>
                    <a href="/aboutMe" className="text-black dark:text-white hover:underline" aria-label="About"  tabIndex="0">About</a>
                    <a href="/privacy_politic" className="text-black dark:text-white hover:underline" aria-label="Política de Privacidad"  tabIndex="0">Política de Privacidad</a>
                    <a href="/contact" className="text-black dark:text-white hover:underline" aria-label="Contacto"  tabIndex="0">Contacto</a>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
