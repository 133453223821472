const LOCAL = process.env.REACT_APP_LOCAL || 'https://quizz4learn.com';
const PORT_LOCAL = process.env.REACT_APP_PORT_LOCAL || '';
const BASE_URL = `${LOCAL}${PORT_LOCAL}`;
const STRIPE_PK = process.env.REACT_APP_STRIPE_KEY
// dockers
//const BASE_URL = "http://python_server:3001";
export const STRIPE_KEY = STRIPE_PK;
export const QUESTIONS_URL = `${BASE_URL}/api/questions`;
export const TINKERS_URL = `${BASE_URL}/api/tinker`;
export const LOGIN_URL = `${BASE_URL}/api/logIn`;
export const SIGNUP_URL = `${BASE_URL}/api/createUser`;
export const TINKERS_SCORE_URL = `${BASE_URL}/api/tinkerScore`;
export const UPDATE_PROFILE_URL = `${BASE_URL}/api/updateProfile`;
export const UPDATE_AVATAR_URL = `${BASE_URL}/api/updateAvatar`;
export const ADD_QUESTIONARY_URL = `${BASE_URL}/api/addQuestionary`;
export const JOIN_ROOM_URL = `${BASE_URL}/api/addRoyale`
export const SAVE_QUESTION_URL = `${BASE_URL}/api/saveQuestions`
export const GET_UNLOCKS = `${BASE_URL}/api/getUnlocks`
export const UNLOCK_ACHIEVEMENTS = `${BASE_URL}/api/unlockAchievements`
export const POST_PROFILE_URL = `${BASE_URL}/api/postProfile`;
export const POST_STATS_URL = `${BASE_URL}/api/userStats`;
export const POST_BDP_AI_URL = `${BASE_URL}/api/bdpAI3`;
export const POST_CREATE_THINKER = `${BASE_URL}/api/createThinkers`;
export const POST_JOIN_THINKER = `${BASE_URL}/api/joinThinker`;
export const POST_ADD_EXPENSE = `${BASE_URL}/api/addExpense`;
export const GET_AI_TOKENS_URL = `${BASE_URL}/api/getAiTokens`;

export const POST_CREATE_CHECKOUT = `${BASE_URL}/api/create-checkout-session`;
//export const POST_CREATE_CHECKOUT = `${BASE_URL}/api/create-checkout-session-test`;

export const GET_TINKERS_SCORE_URL = (id) => `${BASE_URL}/api/getAllScores/${id}`;
export const GET_SESSION_STATUS = (sessionId) => `${BASE_URL}/api/session-status?session_id=${sessionId}`;

export const REQUEST_PASSWORD_RECOVERY_URL = `${BASE_URL}/api/requestPasswordRecovery`;
export const RESET_PASSWORD_URL = `${BASE_URL}/api/resetPassword`;

//export const POST_BDP_AI_URL = `${BASE_URL}/api/bdpAI`; //expensive
