import React from 'react';
import Navbar from "../navBar/navBar";

function AboutMe() {
    return (
        <div className="min-h-screen bg-gray-900 py-10 px-4 flex flex-col items-center">
            <Navbar></Navbar>
            <div className="max-w-4xl mx-auto">
                <h1 className="text-5xl font-bold mb-8 text-cyan-400 neon-shadow">
                    Quizz4Learn
                </h1>
                <h2 className="text-xl font-semibold mb-8 text-gray-400 tracking-widest">
                    ¡Explora, Juega y Aprende!
                </h2>

                <section className="mb-12">
                    <h2 className="text-3xl font-semibold mb-4 text-purple-300 neon-shadow">
                        Creador y Desarrollador
                    </h2>
                    <p className="text-gray-300 text-lg">
                        Nombre: <strong>Carlos Bilbao Lara</strong><br />
                        Email: <strong>carlosbilbao2@gmail.com</strong>
                    </p>
                </section>

                <section className="mb-12">
                    <h2 className="text-3xl font-semibold mb-4 text-purple-300 neon-shadow">
                        Exámenes Inteligentes con BDP AI
                    </h2>
                    <p className="text-gray-300 text-lg leading-relaxed">
                        Gracias a la inteligencia artificial, se ha desarrollado BDP AI que, utilizando el poder de ChatGPT, genera exámenes a partir de cualquier documento (hasta un tamaño máximo), permitiéndote comprobar lo preparado que estás para un examen en cualquier asignatura.
                    </p>
                </section>

                <section className="mb-12">
                    <h2 className="text-3xl font-semibold mb-4 text-purple-300 neon-shadow">
                        Partidas Competitivas
                    </h2>
                    <p className="text-gray-300 text-lg leading-relaxed">
                        ¿Quieres un desafío aún mayor? Crea partidas privadas y compite solo con tus amigos. Puedes subir un documento propio o usar las preguntas ya disponibles en el sistema. ¡Es la manera perfecta de estudiar en grupo o simplemente disfrutar de una divertida competencia de conocimientos!
                    </p>
                </section>
            </div>
        </div>
    );
}

export default AboutMe;
