// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fun_royale2 {
    position: relative;
    text-align: center;
    background-color: #1a1e24;
    color: #c5c6c7;
    border: none;
    border-radius: 5px;
    width: 100%;
    padding: 20px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    margin-bottom: 2vmin;
    background-image: linear-gradient(160deg, #0a0b0d 0%, #161b22 100%);
    overflow: hidden;
}

.fun_royale2::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(160deg, rgba(234, 2, 237, 0.6), rgba(22, 22, 124, 0.6), rgba(0, 212, 255, 0.6));
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 5px;
}

.fun_royale2:hover::before {
    opacity: 1;
}

.fun_royale2:hover {
    transform: scale(1.05);
    color: #ffffff;
    box-shadow: 0px 8px 30px rgba(0, 255, 255, 0.5);
}`, "",{"version":3,"sources":["webpack://./src/components/questionMode/questionsMenu.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,eAAe;IACf,2FAA2F;IAC3F,oBAAoB;IACpB,mEAAmE;IACnE,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,SAAS;IACT,OAAO;IACP,2GAA2G;IAC3G,UAAU;IACV,oCAAoC;IACpC,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,+CAA+C;AACnD","sourcesContent":[".fun_royale2 {\n    position: relative;\n    text-align: center;\n    background-color: #1a1e24;\n    color: #c5c6c7;\n    border: none;\n    border-radius: 5px;\n    width: 100%;\n    padding: 20px;\n    font-size: 1.2rem;\n    cursor: pointer;\n    transition: transform 0.2s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;\n    margin-bottom: 2vmin;\n    background-image: linear-gradient(160deg, #0a0b0d 0%, #161b22 100%);\n    overflow: hidden;\n}\n\n.fun_royale2::before {\n    content: \"\";\n    position: absolute;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    background: linear-gradient(160deg, rgba(234, 2, 237, 0.6), rgba(22, 22, 124, 0.6), rgba(0, 212, 255, 0.6));\n    opacity: 0;\n    transition: opacity 0.3s ease-in-out;\n    border-radius: 5px;\n}\n\n.fun_royale2:hover::before {\n    opacity: 1;\n}\n\n.fun_royale2:hover {\n    transform: scale(1.05);\n    color: #ffffff;\n    box-shadow: 0px 8px 30px rgba(0, 255, 255, 0.5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
