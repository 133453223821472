// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `    .input {
      width: 93%;
      padding: 2vmin;
      margin-bottom: 1vmin;
      border-radius: 5px;
      border: 1px solid #ddd;
    }

    .button {
      width: 101.5%;
      padding: 2vmin;
      border-radius: 5px;
      border: none;
      background-color: #28a745;
      color: #fff;
      cursor: pointer;
    }

    .linked {
      background: none;
      border: none;
      cursor: pointer;
      font-size: 16px;
      text-decoration: underline;
      outline: none;
      position: relative;
      transition: text-shadow 0.4s ease-in-out;
      color: #ffffff;
    }

    .linked:hover {
      text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.8);
      animation: glowEffect 0.4s forwards;
    }

    @keyframes glowEffect {
      0% {
        text-shadow: 0px 0px 5px rgba(255, 255, 255, 0);
      }

      100% {
        text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.8);
      }
    }`, "",{"version":3,"sources":["webpack://./src/components/logIn/logIn.css"],"names":[],"mappings":"IAAI;MACE,UAAU;MACV,cAAc;MACd,oBAAoB;MACpB,kBAAkB;MAClB,sBAAsB;IACxB;;IAEA;MACE,aAAa;MACb,cAAc;MACd,kBAAkB;MAClB,YAAY;MACZ,yBAAyB;MACzB,WAAW;MACX,eAAe;IACjB;;IAEA;MACE,gBAAgB;MAChB,YAAY;MACZ,eAAe;MACf,eAAe;MACf,0BAA0B;MAC1B,aAAa;MACb,kBAAkB;MAClB,wCAAwC;MACxC,cAAc;IAChB;;IAEA;MACE,iDAAiD;MACjD,mCAAmC;IACrC;;IAEA;MACE;QACE,+CAA+C;MACjD;;MAEA;QACE,iDAAiD;MACnD;IACF","sourcesContent":["    .input {\n      width: 93%;\n      padding: 2vmin;\n      margin-bottom: 1vmin;\n      border-radius: 5px;\n      border: 1px solid #ddd;\n    }\n\n    .button {\n      width: 101.5%;\n      padding: 2vmin;\n      border-radius: 5px;\n      border: none;\n      background-color: #28a745;\n      color: #fff;\n      cursor: pointer;\n    }\n\n    .linked {\n      background: none;\n      border: none;\n      cursor: pointer;\n      font-size: 16px;\n      text-decoration: underline;\n      outline: none;\n      position: relative;\n      transition: text-shadow 0.4s ease-in-out;\n      color: #ffffff;\n    }\n\n    .linked:hover {\n      text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.8);\n      animation: glowEffect 0.4s forwards;\n    }\n\n    @keyframes glowEffect {\n      0% {\n        text-shadow: 0px 0px 5px rgba(255, 255, 255, 0);\n      }\n\n      100% {\n        text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.8);\n      }\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
