import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCookieValue } from "../../authSlide";

const ConfirmEmail = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-900 py-10 px-4 flex flex-col items-center">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-5xl font-bold mb-8 text-cyan-400 neon-shadow">
          Confirme su correo
        </h1>
        <p className="text-xl font-semibold mb-8 text-gray-400 tracking-widest">
          Haga click en el enlace de confirmación que le hemos enviado a su correo para poder acceder a la aplicación.
        </p>
        <button
          onClick={() => window.location.href = "/login"}
          className="relative inline-flex h-12 overflow-hidden rounded-lg p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 dark:focus:ring-offset-slate-800 transition-all duration-300 hover:bg-slate-950 dark:hover:bg-slate-100"
        >
          <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
          <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-lg bg-slate-100 dark:bg-slate-950 px-6 py-2 text-sm font-medium text-black dark:text-white backdrop-blur-3xl transition-all duration-300 hover:bg-slate-950 hover:text-white dark:hover:bg-slate-100 dark:hover:text-black">
            Ya ha confirmado? Inicie sesión!!
          </span>
        </button>
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
      </div>
    </div>
  );
};

export default ConfirmEmail;
