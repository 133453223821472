import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { motion } from 'framer-motion';
import AuroraBackground from '../describe/aurora';
import Navbar from '../navBar/navBar';
const PlanCard = ({ title, price, features, buttonText, onClick, popular }) => (
    <div className={`relative bg-white rounded-xl shadow-lg p-6 text-gray-800 dark:bg-black dark:text-gray-200 flex flex-col ${popular ? 'border-4 border-indigo-600' : ''}`}>
        {popular && (
            <div className="absolute top-0 right-0 bg-indigo-600  text-white text-xs font-bold px-2 py-1 rounded-bl-lg">
                ¡Más Popular!
            </div>
        )}
        <h3 className="text-xl font-semibold mb-4 text-center text-indigo-600">{title}</h3>
        <p className="text-center text-4xl font-bold mb-6">{price}</p>
        <ul className="flex-1 space-y-2 mb-6">
            {features.map((feature, index) => (
                <li key={index} className="flex items-start">
                    <FaCheckCircle className="text-green-500 mt-1 mr-2" />
                    <span>{feature}</span>
                </li>
            ))}
        </ul>
        <button
            className="w-full py-3 bg-indigo-600 text-white rounded-lg font-semibold hover:bg-indigo-700 transition duration-300"
            onClick={onClick}
        >
            {buttonText}
        </button>
    </div>
);

const Offers = () => {
    const plans = [
        {
            title: 'Básico',
            price: '€5',
            features: [
                'Acceso a 20 Q4L AI tokens',
                'Crea partidas privadas',
                'Icono de perfil exclusivo',
            ],
            buttonText: 'Elegir Básico',
            onClick: () => window.location.href = '/checkout',
        },
        {
            title: 'Estándar',
            price: '€10',
            features: [
                'Acceso a 40 Q4L AI tokens +20 de regalo',
                'Crea partidas privadas a partir de Q4L AI',
                'Sube tus propias preguntas (próximamente)',
                'Icono de perfil exclusivo',
            ],
            buttonText: 'Elegir Estándar',
            onClick: () => window.location.href = 'https://buy.stripe.com/standard-plan',
            popular: true,
        },
        {
            title: 'Premium',
            price: '€20',
            features: [
                'Acceso a 80 a Q4L AI tokens +40 de regalo',
                'Crea partidas privadas a partir de Q4L AI',
                'Sube tus propias preguntas (próximamente)',
                'Icono de perfil exclusivo',
            ],
            buttonText: 'Elegir Premium',
            onClick: () => window.location.href = 'https://buy.stripe.com/premium-plan',
        },
    ];

    return (
        <AuroraBackground>
            <motion.div
                initial={{ opacity: 0.0, y: 40 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{
                    delay: 0.3,
                    duration: 0.8,
                    ease: "easeInOut",
                }}
                className="relative flex flex-col gap-4 items-center justify-center px-4"
            >
                <Navbar />
                <h2 className="text-3xl font-bold mb-8 text-center text-indigo-600 dark:text-indigo-400">
                    ¡Elige el plan que más te convenga!
                </h2>
                <div className="grid md:grid-cols-3 gap-8">
                    {plans.map((plan, index) => (
                        <PlanCard
                            key={index}
                            title={plan.title}
                            price={plan.price}
                            features={plan.features}
                            buttonText={plan.buttonText}
                            onClick={plan.onClick}
                            popular={plan.popular}
                        />
                    ))}
                </div>
                {/* Disclaimer */}
                <p className="text-sm text-gray-600 dark:text-white mt-8 text-center max-w-2xl">
                    Los tokens caducarán al año de su compra o en caso de que la aplicación cierre. No hay posibilidad de devolución ya que se consumen en el acto de la compra.
                </p>

            </motion.div>
        </AuroraBackground>
    );
};

export default Offers;
